<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="gethelpText">
        <i class="fas fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'bettwasche-help',
    	props: {

    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = '';

    			return titleText;
    		},
    		gethelpText: function() {
    			//console.log(this.pricelist);
    			var helpText = 'Beinhaltet 1 x Bettwäsche inkl. Bettlaken';
    			return helpText;
    		}
    	}
    };
</script>