import { render, staticRenderFns } from "./offershelp.vue?vue&type=template&id=596be232&"
import script from "./offershelp.vue?vue&type=script&lang=js&"
export * from "./offershelp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('596be232')) {
      api.createRecord('596be232', component.options)
    } else {
      api.reload('596be232', component.options)
    }
    module.hot.accept("./offershelp.vue?vue&type=template&id=596be232&", function () {
      api.rerender('596be232', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8ss635d2d6WJ7/JS/offershelp.vue"
export default component.exports