import { render, staticRenderFns } from "./SearchFilterTokens.vue?vue&type=template&id=700e37ca&scoped=true&"
import script from "./SearchFilterTokens.vue?vue&type=script&lang=js&"
export * from "./SearchFilterTokens.vue?vue&type=script&lang=js&"
import style0 from "./SearchFilterTokens.vue?vue&type=style&index=0&id=700e37ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700e37ca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('700e37ca')) {
      api.createRecord('700e37ca', component.options)
    } else {
      api.reload('700e37ca', component.options)
    }
    module.hot.accept("./SearchFilterTokens.vue?vue&type=template&id=700e37ca&scoped=true&", function () {
      api.rerender('700e37ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8ss635d2d6WJ7/JS/comp/SearchFilterTokens.vue"
export default component.exports